import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-sitegörselleri",
  templateUrl: "./sitegörselleri.component.html",
  styleUrls: ["./sitegörselleri.component.css"],
})
export class SitegörselleriComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
