import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-intro",
  templateUrl: "./hizmetlerimiz.component.html",
  styleUrls: ["./hizmetlerimiz.component.css"],
})
export class HizmetlerimizComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
