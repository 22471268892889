import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-faaliyet",
  templateUrl: "./faaliyet.component.html",
  styleUrls: ["./faaliyet.component.css"],
})
export class FaaliyetComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
