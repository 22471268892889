import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-duyuru1",
  templateUrl: "./duyuru1.component.html",
  styleUrls: ["./duyuru1.component.css"],
})
export class Duyuru1Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
