import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-duyuru3",
  templateUrl: "./duyuru3.component.html",
  styleUrls: ["./duyuru3.component.css"],
})
export class Duyuru3Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
